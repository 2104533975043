* {
  margin: 0;
  padding: 0;
}

.front-section {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.front-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/front-banner-background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.front-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: -1;
}
.navbar {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  padding: 8px 0;
}

.navbar-items {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  position: relative;
  padding-bottom: 5px;
  color: white;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: rgb(163 136 43);
}

.nav-link.active {
  color: white;
}

.nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: white;
}

li {
  list-style: none;
}

.align-right {
  justify-content: flex-end;
}

.ml-2 {
  margin-left: 10rem;
}

.line {
  height: 3px;
  width: 80px;
  border-radius: 10px;
  background-color: rgb(163 136 43);
}

.fs-3r {
  font-size: 3.5rem;
}

.btn-size {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.border-none {
  border: none;
}

.border-white {
  border: 1px solid white;
}

.bg-gold {
  background-color: rgb(163 136 43);
}

.bg-transparent {
  background-color: transparent;
}

.numbers-section {
  position: relative;
  background-image: url("./img/law-environment.jpg");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  width: 100%;
  overflow: hidden;
}

.numbers-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: rgba(255, 255, 255, 0);
  z-index: 1;
}

.numbers-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: 2;
}

.navbar.scrolled {
  background: rgba(15, 29, 64, 0.8);
}

.numbers-section > * {
  position: relative;
  z-index: 3;
}

.card {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  width: 100%;
}

a {
  text-decoration: none;
}

a h4 {
  color: black;
}

.sub-services-section {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.582);
}

.sub-services-section:hover {
  color: black;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.smooth-collapse {
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.card .collapse.show {
  height: auto;
  opacity: 1;
}

.card .collapse:not(.show) {
  height: 0;
  opacity: 0;
}

.navbar-toggler {
  text-align: center;
  border-color: white;
}

.navbar-toggler-icon {
  background-image: none;
  color: white;
  font-size: 24px;
}

.color-gold {
  color: rgb(163 136 43);
}

.navbar-toggler-icon::before {
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: white;
  padding: auto;
}

.who-we-are-card {
  height: 100%;
  width: 100%;
}

.card-body p {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.589);
}

.numbers-sections-img {
  width: 30%;
  height: 60%;
  background-size: contain;
  background-position: center;
}

.numbers-section {
  color: white;
}

.bg-blue {
  background-color: rgb(25, 29, 51);
  width: 100%;
  height: 100%;
  color: white;
}

.contact-main-section {
  position: relative;
  height: 60vh;
  width: 100%;
  overflow: hidden;
}

.contact-main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/contact-page.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.contact-main-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: -1;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
}

.form-group input:focus,
.form-group select:focus {
  border-color: rgb(163, 136, 43);
  outline: none;
}

.form-group label {
  position: absolute;
  left: 10px;
  top: 16px;
  padding: 0 5px;
  color: #666;
  font-size: 16px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  pointer-events: none;
  white-space: nowrap;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group select:focus + label,
.form-group select.has-value + label {
  top: -10px;
  left: 8px;
  font-size: 12px;
  color: #666;
}

.form-group input + label::after {
  content: "*";
  color: red;
  margin-left: 5px;
  display: none;
}

.form-group input:focus + label::after,
.form-group input:not(:placeholder-shown) + label::after {
  display: inline;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: rgb(163, 136, 43);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: rgb(25, 29, 51);
}

.error-message {
  color: red;
}

select:disabled {
  cursor: not-allowed;
}

.carousel {
  width: 100%;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}

.carousel-card {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills-icon-size {
  width: 100%;
  height: 110px;
  object-fit: contain;
  filter: contrast(110%) brightness(105%);
  transition: transform 0.3s ease, filter 0.3s ease;
}

.skills-icon-size:hover {
  transform: scale(1.1);
  filter: contrast(120%) brightness(115%);
}

.about-main-section {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.about-main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/about.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.about-main-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: -1;
}

.about-img {
  width: 100%;
  height: 100%;
}

.service-main-section {
  position: relative;
  height: 80vh;
  width: 100%;
  overflow: hidden;
}

.service-main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/services.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.service-main-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: -1;
}

.card li {
  list-style: disc;
}

.text-black {
  color: black;
}

.fs-16 {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.circle {
  border-radius: 50%;
  background-color: rgb(163, 136, 43);
  color: white;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 3rem;
}

.mission-section {
  position: relative;
  background-image: url("./img/mission.jpg");
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: auto;
  width: 100%;
  overflow: hidden;
}

.mission-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: rgba(255, 255, 255, 0);
  z-index: 1;
}

.mission-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: 2;
}

.mission-section > * {
  position: relative;
  z-index: 3;
}

.case-study-main-section {
  position: relative;
  height: 60vh;
  width: 100%;
  overflow: hidden;
}

.case-study-main-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./img/case-studies.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.case-study-main-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 29, 64, 0.5);
  z-index: -1;
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
  margin: 20px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.badge {
  background-color: rgb(163, 136, 43);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  top: 1rem;
  right: 1rem;
}

@media (max-width: 576px) {
  .padding-all {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .padding-l {
    padding-left: 1.3rem !important;
  }
  .skills-icon-size {
    width: 60px;
  }
  .fs-3r {
    font-size: 2.5rem;
  }
  .navbar-items {
    display: list-item;
    margin: 10px 0;
  }
  .navbar {
    background-color: rgb(25, 29, 51);
  }
}

@media (max-width: 768px) {
  .skills-icon-size {
    width: 100px;
  }
  .fs-3r {
    font-size: 2.5rem;
  }
  .navbar-items {
    display: list-item;
    margin: 10px 0;
  }
  .navbar {
    background-color: rgb(25, 29, 51);
  }
}
